// --------- ATENCION: ---------------------
// NO USAR tildes ni caracteres extranos en los comentarios
// Esto aniade una linea de chartset al inicio de la css compilada que
// genera un error


/* ---------------------------------------------- */
/* Estilos para el paquete de Avisos Obligatorios */
/* ---------------------------------------------- */


.aviso-obligatorio {
    //background-color: #eaeaea;
	margin: 0 auto;
	font-size: 125%;
	display: flex;
    flex-direction: column;
    gap: 1rem;

    .cuerpoAviso {
        font-size: 1.25rem;
        font-weight: 300;
        line-height: 1.65em;
        display: inline-block;

        .image-left  {
            margin-right: 1rem;
            float: left;
        }

        .image-right  {
            margin-left: 1rem;
            float: right;
        }

        .image-left,
        .image-right {
            @media (max-width: 767.98px) {
                float: inherit;
                margin: 2rem auto !important;
            }
        }
    }
}


.btn-primary {

    &:hover,
    &:focus {
        border: .125rem solid #fff !important;
        border-radius: .125rem;
        outline: .125rem solid #222921;
        text-decoration: none;
    }
}

.btn-secondary {
    background-color: #222921 !important;

    &:hover,
    &:focus {
        border: .125rem solid #fff !important;
        border-radius: .125rem;
        outline: .125rem solid #222921;
        text-decoration: none; 
    }
}

// Se aniaden estos estilos por estar fuera del #content

#avisos-container.aviso-obligatorio a.internal-link,
#avisos-container.aviso-obligatorio a.external-link,
#avisos-container.aviso-obligatorio a.internal-link > span.tituloArchivo,
#avisos-container.aviso-obligatorio a.external-link > span.tituloArchivo {display:inline;position:relative;text-decoration:underline;border-bottom:none;}

#avisos-container.aviso-obligatorio form#feedback_form {margin-top:1em;}


// Oculta los elementos que no queremos que se muestren.
// Se aniade la clase por js.
// Se utiliza !important para pisar cualquier regla
.no-mostrar-avisos-obligatorios {display:none !important;visibility:hidden !important;}

